export default {
  'en-us': {
    home: 'Homepage',
    help: 'Help',
    login: 'Login',
    logout: 'Logout',
  },
  'pt-br': {
    home: 'Início',
    help: 'Ajuda',
    error_401: 'Usuário inexistente ou senha incorreta',
    login: 'Conectar',
    logout: 'Sair',
    check: 'Cheque',
    money: 'Em espécie',
    credit: 'Cartão de crédito',
    debit: 'Cartão de débito',
  }
}
