import { auth } from '@/lib/firebase'
import api from '@/lib/api'

const SET_BANKS = 'SET_BANKS';
const SET_IS_LOADED = 'SET_IS_LOADED';

const GET_BANKS = 'GET_BANKS';
const GET_IS_LOADED = 'GET_IS_LOADED';

const RESET = 'RESET';

const getDefaultState = () => ({
  banks: [],
  isAddingBank: false,
  isLoaded: false,
  isLoadingBanks: true,
  recipients: [],
  isLoadingRecipients: true,
});

export const state = getDefaultState();

export const mutations = {
  [SET_BANKS](state, banks) {
    state.banks = banks
  },
  ADD_BANK(state, bank) {
    state.banks.push(bank)
  },
  [SET_IS_LOADED](state, isLoaded) {
    state.isLoaded = isLoaded
  },
  SET_IS_LOADING_BANKS(state, isLoadingBanks) {
    state.isLoadingBanks = isLoadingBanks
  },
  SET_RECIPIENTS(state, recipients) {
    state.recipients = recipients
  },
  SET_IS_LOADING_RECIPIENTS(state, isLoadingRecipients) {
    state.isLoadingRecipients = isLoadingRecipients
  },

  [RESET](state) {
    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  [GET_BANKS](state) {
    return state.banks
  },
  GET_IS_ADDING_BANK(state) {
    return state.isAddingBank
  },
  [GET_IS_LOADED](state) {
    return state.isLoaded;
  },
  GET_RECIPIENTS(state) {
    return state.recipients
  },
  GET_IS_LOADING_RECIPIENTS(state) {
    return state.isLoadingRecipients
  },
}

export const actions = {
  async fetch({ commit, getters }, { receiverId, useCache } = {}) {
    if (getters.GET_IS_LOADED && useCache) {
      return getters.GET_BANKS;
    }

    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/banks',
        {
          params: { receiverId },
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      commit(SET_BANKS, response.data);
      commit(SET_IS_LOADED, true);

    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  fetchRecipients({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_IS_LOADING_RECIPIENTS', true)
      auth.currentUser.getIdToken().then((token) => {
        api.get(
          '/banks/recipients',
          { headers: { Authorization: `Bearer ${token}` } }
        ).then((response) => {
          const recipients = response.data;
          commit('SET_RECIPIENTS', recipients);
          commit('SET_IS_LOADING_RECIPIENTS', false);
          resolve();
        }).catch((err) => {
          reject(err)
        })
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async add({ commit, getters }, { bank, receiverId }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/banks',
        { bank, receiverId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      const newBank = response.data;
      const banks = getters.GET_BANKS || [];

      commit(SET_BANKS, [ newBank, ...banks ]);
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async delete({ commit, getters }, { id }) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.delete(
        '/banks',
        {
          params: { id },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const banks = getters.GET_BANKS || [];
      commit(SET_BANKS, banks.filter((b) => b.id !== id));
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async savePixKey({ commit, getters }, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/banks/pix',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const banks = getters.GET_BANKS || [];
      const index = banks.findIndex((b) => b.id === payload.bankId);

      if (index >= 0) {
        banks[index].pix = {
          key: payload.key,
          type: payload.type,
        };
      }

      commit(SET_BANKS, [ ...banks ]);
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async removePixKey({ commit, getters }, bankId) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.delete(
        '/banks/pix',
        { 
          params: { bankId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const banks = getters.GET_BANKS || [];
      const index = banks.findIndex((b) => b.id === bankId);

      if (index >= 0 && banks[index].pix) {
        delete banks[index].pix;
      }

      commit(SET_BANKS, [ ...banks ]);
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  reset({ commit }) {
    commit(RESET);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
