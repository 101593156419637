import Vue from 'vue';
import { firebase, firestoreDb, auth, handleDoc } from '@/lib/firebase';
import { doctorBaseQuery, repeatEvent, doctorRepeatingEvents } from '@/utils/event-helper';
import DateHelper from '@/utils/dateHelper';
import api from '@/lib/api';

const ADD_DOCTOR_UNSUBSCRIBE = 'ADD_DOCTOR_UNSUBSCRIBE';
const ADD_OWNER_UNSUBSCRIBE = 'ADD_OWNER_UNSUBSCRIBE';
const ALL_DOCTOR_EVENTS = 'ALL_DOCTOR_EVENTS';
const SET_DOCTOR_EVENTS = 'SET_DOCTOR_EVENTS';
const SET_DOCTOR_SUBSCRIBED_PERIODS = 'SET_DOCTOR_SUBSCRIBED_PERIODS';
const ALL_OWNER_EVENTS = 'ALL_OWNER_EVENTS';
const SET_OWNER_EVENTS = 'SET_OWNER_EVENTS';
const SET_OWNER_SUBSCRIBED_PERIODS = 'SET_OWNER_SUBSCRIBED_PERIODS';
const SET_HAS_PENDING_UI_REFRESH = 'SET_HAS_PENDING_UI_REFRESH';
const REMOVE_DOCTOR_EVENTS = 'REMOVE_DOCTOR_EVENTS';
const REMOVE_OWNER_EVENTS = 'REMOVE_OWNER_EVENTS';
const RESET = 'RESET';
const UNSUBSCRIBE_DOCTOR = 'UNSUBSCRIBE_DOCTOR';
const UNSUBSCRIBE_OWNER = 'UNSUBSCRIBE_OWNER';

const LIMIT_SUBSCRIPTIONS = 5;

const getDefaultState = () => ({
  events: [],
  ownerEvents: {},
  ownerSubscribedPeriods: [],
  ownerUnsubscribes: {},
  doctorEvents: {},
  doctorSubscribedPeriods: [],
  doctorUnsubscribes: {},
  eventUnsubscribe: null,
  hasPendingUiRefresh: false,
});

export const state = getDefaultState();

export const mutations = {
  SET_EVENTS(state, { events }) {
    state.events = events;
  },
  [SET_DOCTOR_EVENTS](state, { period, once, repeating }) {
    if (!state.doctorEvents[period]) {
      Vue.set(state.doctorEvents, period, {});
    }

    if (once) {
      Vue.set(state.doctorEvents[period], 'once', once);
    }

    if (repeating) {
      Vue.set(state.doctorEvents[period], 'repeating', repeating);
    }
  },
  [SET_OWNER_EVENTS](state, { period, once, repeating }) {
    if (!state.ownerEvents[period]) {
      Vue.set(state.ownerEvents, period, {});
    }

    if (once) {
      Vue.set(state.ownerEvents[period], 'once', once);
    }

    if (repeating) {
      Vue.set(state.ownerEvents[period], 'repeating', repeating);
    }
  },
  SET_EVENT(state, { index, event }) {
    Vue.set(state.events, index, event);
  },
  [SET_DOCTOR_SUBSCRIBED_PERIODS](state, periods) {
    state.doctorSubscribedPeriods = periods;
  },
  [SET_OWNER_SUBSCRIBED_PERIODS](state, periods) {
    state.ownerSubscribedPeriods = periods;
  },
  ADD_EVENT(state, { event }) {
    // state.events.push(event)
    // prepend for visibility
    state.events = [event, ...state.events];
  },
  [ADD_DOCTOR_UNSUBSCRIBE](state, { unsubscribe, period }) {
    // state.events.push(event)
    // prepend for visibility
    state.doctorUnsubscribes[period] = unsubscribe;
  },
  [ADD_OWNER_UNSUBSCRIBE](state, { unsubscribe, period }) {
    // state.events.push(event)
    // prepend for visibility
    state.ownerUnsubscribes[period] = unsubscribe;
  },
  REMOVE_EVENT(state, { index }) {
    state.events.splice(index, 1);
  },
  [REMOVE_DOCTOR_EVENTS](state, period) {
    Vue.delete(state.doctorEvents, period);
  },
  [REMOVE_OWNER_EVENTS](state, period) {
    Vue.delete(state.ownerEvents, period);
  },

  SUBSCRIBE_OR_RESUBSCRIBE(state, { eventUnsubscribe }) {
    if (state.eventUnsubscribe) {
      state.eventUnsubscribe();
    }
    state.eventUnsubscribe = eventUnsubscribe;
  },

  [UNSUBSCRIBE_DOCTOR](state, period) {
    if (state.doctorUnsubscribes[period]) {
      state.doctorUnsubscribes[period]?.();
    }
  },

  [UNSUBSCRIBE_OWNER](state, period) {
    if (state.ownerUnsubscribes[period]) {
      state.ownerUnsubscribes[period]?.();
    }
  },

  [SET_HAS_PENDING_UI_REFRESH](state, hasPendingUiRefresh) {
    state.hasPendingUiRefresh = hasPendingUiRefresh;
  },

  [RESET](state) {
    if (state.eventUnsubscribe) {
      state.eventUnsubscribe();
    }

    if (state.doctorUnsubscribes) {
      Object.keys(state.doctorUnsubscribes).forEach((key) => {
        state.doctorUnsubscribes[key]?.();
      });
    }

    if (state.ownerUnsubscribes) {
      Object.keys(state.ownerUnsubscribes).forEach((key) => {
        state.ownerUnsubscribes[key]?.();
      });
    }

    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  GET_EVENTS(state) {
    return state.events;
  },
  GET_HAS_PENDING_UI_REFRESH(state) {
    return state.hasPendingUiRefresh;
  },
  [ALL_DOCTOR_EVENTS](state) {
    return Object.keys(state.doctorEvents).reduce((result, key) => [
      ...result,
      ...(state.doctorEvents[key]?.once || []),
      ...(state.doctorEvents[key]?.repeating || []),
    ], []);
  },
  [ALL_OWNER_EVENTS](state) {
    return Object.keys(state.ownerEvents).reduce((result, key) => [
      ...result,
      ...(state.ownerEvents[key]?.once || []),
      ...(state.ownerEvents[key]?.repeating || []),
    ], []);
  }
}

export const actions = {
  subscribe({ state, commit, rootGetters }, { role, spaceId }) {
    if (role !== 'doctor' && role !== 'owner') {
      throw Error(`Unknown role: ${role}`)
    }

    // TODO avoid subscription of ALL events (can be too heavy): use start/end
    const onSnapshot = ({ resolve }) => (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const event = { id: change.doc.id, ...change.doc.data() };
        if (change.type === 'added') {
          commit('ADD_EVENT', { event });
        } else if (change.type === 'removed') {
          commit('REMOVE_EVENT', { index: state.events.findIndex(e => e.id === change.doc.id) });
        } else if (change.type === 'modified') {
          commit('SET_EVENT', { index: state.events.findIndex(m => m.id === change.doc.id), event });
        }
      })
      commit(SET_HAS_PENDING_UI_REFRESH, true);
      resolve();
    }

    commit('SET_EVENTS', { events: [] });
    const userUid = rootGetters['user/GET_USER_UID'];

    // TODO use .where('start', '<=', end)
    return new Promise((resolve, reject) => {
      let query = firestoreDb.collection('events')
        .where(role === 'doctor' ? 'userId' : 'ownerId', '==', userUid)
        .where('scheduleType', '==', 'once');

      if (spaceId) {
        query = query.where('spaceId', '==', spaceId);
      }

      const eventUnsubscribe = query.onSnapshot(onSnapshot({ resolve }), reject);
      commit('SUBSCRIBE_OR_RESUBSCRIBE', { eventUnsubscribe });
    })
  },

  async subscribeOwner({ state, commit, rootGetters }, { month, year, spaceId, userId }) {
    const userUid = userId || rootGetters['user/GET_USER_UID'];
    const period = `${ year }-${ month }${ userUid }`;
    const start = DateHelper.date().month(month).year(year).startOf('month');
    const end = DateHelper.date().month(month).year(year).endOf('month');
    const ownerSubscribedPeriods = state.ownerSubscribedPeriods;
    const oldIndex = ownerSubscribedPeriods.findIndex((p) => p === period);

    // Check if already subscribed
    if (oldIndex >= 0) {
      ownerSubscribedPeriods.splice(oldIndex, 1);
      ownerSubscribedPeriods.splice(0, 0, period);

      commit(SET_OWNER_SUBSCRIBED_PERIODS, ownerSubscribedPeriods);
    } else {
      // If reached limit, pop last
      if (ownerSubscribedPeriods.length >= LIMIT_SUBSCRIPTIONS) {
        const last = ownerSubscribedPeriods.pop();
        commit(UNSUBSCRIBE_OWNER, last);
        commit(REMOVE_OWNER_EVENTS, last);
      }
  
      ownerSubscribedPeriods.splice(0, 0, period);
  
      // TODO avoid subscription of ALL events (can be too heavy): use start/end
      const onSnapshot = ({ resolve }) => (snapshot) => {
        const events = state.ownerEvents[period].once;

        snapshot.docChanges().forEach((change) => {
          const event = { id: change.doc.id, ...change.doc.data() };
  
          if (change.type === 'added') {
            events.unshift(event);

            commit(SET_OWNER_EVENTS, { period, once: [ ...events ] });
          } else if (change.type === 'removed') {
            const index = events.findIndex(e => e.id === change.doc.id);
  
            console.log('removed', index, events.length)

            if (index >= 0) {
              events.splice(index, 1);
              commit(SET_OWNER_EVENTS, { period, once: [ ...events ] });
            }

            console.log(events)
          } else if (change.type === 'modified') {
            console.log('--> modified', event)
            const index = events.findIndex(m => m.id === change.doc.id);
  
            if (index >= 0) {
              events[index] = event;
              commit(SET_OWNER_EVENTS, { period, once: [ ...events ] });
            }
          }
        });
        commit(SET_HAS_PENDING_UI_REFRESH, true);
        resolve();
      }
  
      commit(SET_OWNER_EVENTS, { period, once: [], repeating: [] });
      commit(SET_OWNER_SUBSCRIBED_PERIODS, ownerSubscribedPeriods);    
  
      await new Promise((resolve, reject) => {
        let query = firestoreDb.collection('events')
          .where('ownerId', '==', userUid)
          .where('scheduleType', '==', 'once')
          .where('start', '>=', start.toDate())
          .where('start', '<=', end.toDate());
  
        const unsubscribe = query.onSnapshot(onSnapshot({ resolve }), reject);
        commit(ADD_OWNER_UNSUBSCRIBE, { unsubscribe, period });
      })
    }

    const filteredEvents = state.ownerEvents[period].once || [];
    const referenced = filteredEvents.map((e) => e.referenceId).filter((r) => !!r);

    const repeating = async () => {
      let query = firestoreDb
        .collection('events')
        .where('ownerId', '==', userUid)
        .where('scheduleType', '==', 'repeating')
        .where('details.expiresAt', '>=', start.toDate());

      if (spaceId) {
        query = query.where('spaceId', '==', spaceId);
      }

      return query
        .get()
        .then((snapshot) => {
          const events = [];
          snapshot.docs.forEach((d) => {
            const event = { id: d.id, ...d.data() };
            events.push(...repeatEvent(event, start.startOf('day').toDate(), end.endOf('day').toDate(), referenced));
          });
          return events;
        });
    };

    const repeatingEvents = await repeating();
    commit(SET_OWNER_EVENTS, { period, repeating: [ ...repeatingEvents ] });
    commit(SET_HAS_PENDING_UI_REFRESH, true);
  },

  async subscribeDoctor({ state, commit, rootGetters }, { month, year }) {
    const userUid = rootGetters['user/GET_USER_UID'];
    const period = `${ year }-${ month }`;
    const start = DateHelper.date().month(month).year(year).startOf('month');
    const end = DateHelper.date().month(month).year(year).endOf('month');
    const subscribedPeriods = state.doctorSubscribedPeriods;
    const oldIndex = subscribedPeriods.findIndex((p) => p === period);

    // Check if already subscribed
    if (oldIndex >= 0) {
      subscribedPeriods.splice(oldIndex, 1);
      subscribedPeriods.splice(0, 0, period);

      commit(SET_DOCTOR_SUBSCRIBED_PERIODS, subscribedPeriods);
    } else {
      // If reached limit, pop last
      if (subscribedPeriods.length >= LIMIT_SUBSCRIPTIONS) {
        const last = subscribedPeriods.pop();
        commit(UNSUBSCRIBE_DOCTOR, last);
        commit(REMOVE_DOCTOR_EVENTS, last);
      }

      subscribedPeriods.splice(0, 0, period);
  
      // TODO avoid subscription of ALL events (can be too heavy): use start/end
      const onSnapshot = ({ resolve }) => (snapshot) => {
        const events = [...state.doctorEvents[period].once];

        snapshot.docChanges().forEach(async (change) => {
          const event = { id: change.doc.id, ...change.doc.data() };

          if (event.eventType === 'unavailable') { return; }

          if (change.type === 'added') {
            // For each event, fetch space by spaceId and add name
            const space = await firestoreDb.collection('spaces').doc(event.spaceId).get().then(handleDoc);

            events.unshift({
              ...event,
              space: {
                ...(event.space ?? {}),
                name: space?.name,
              },
            });

            commit(SET_DOCTOR_EVENTS, { period, once: [ ...events ] });
          } else if (change.type === 'removed') {
            const index = events.findIndex(e => e.id === change.doc.id);
  
            if (index >= 0) {
              events.splice(index, 1);
              commit(SET_DOCTOR_EVENTS, { period, once: [ ...events ] });
            }
          } else if (change.type === 'modified') {
            const index = events.findIndex(m => m.id === change.doc.id);

            if (index >= 0) {
              events[index] = event;
              commit(SET_DOCTOR_EVENTS, { period, once: [ ...events ] });
            }
          }
        });
        commit(SET_HAS_PENDING_UI_REFRESH, true);
        resolve();
      }
  
      commit(SET_DOCTOR_EVENTS, { period, once: [], repeating: [] });
      commit(SET_DOCTOR_SUBSCRIBED_PERIODS, subscribedPeriods);    

      await new Promise((resolve, reject) => {
        let query = doctorBaseQuery(userUid, start, end);

        const unsubscribe = query.onSnapshot(onSnapshot({ resolve }), reject);
        commit(ADD_DOCTOR_UNSUBSCRIBE, { unsubscribe, period });
      });
    }

    const repeatingEvents = await doctorRepeatingEvents(userUid, start, end);
    commit(SET_DOCTOR_EVENTS, { period, repeating: [ ...repeatingEvents ] });
    commit(SET_HAS_PENDING_UI_REFRESH, true);
  },

  unsubscribe({ state }) {
    if (state.eventUnsubscribe) {
      state.eventUnsubscribe()
    }
  },

  delete({ getters, commit }, { usingSubscription, eventId }) {
    return new Promise((resolve, reject) => {
      const index = getters.GET_EVENTS.findIndex(e => e.id === eventId)
      if (index === -1) {
        reject('Evento não encontrado.')
        return
      }
      firestoreDb.collection('events').doc(eventId).delete()
        .then(() => {
          if (!usingSubscription) {
            commit('REMOVE_EVENT', { index })
          }
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async fetch(_, id) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/events',
        {
          params: { id },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async cancel(_, eventId) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/events/cancel',
        { eventId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async update(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/events/update',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async extendTimeDetails(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/events/extend-time/details',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async extendTime(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/events/extend-time',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async confirmExit(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/events/exit',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async markAsCancelled(_, eventId) {
    const token = await auth.currentUser.getIdToken();
    
    try {
      await api.post(
        '/events/cancel',
        { eventId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  markAsAvailable(_, { eventId, slot }) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/events/available',
          { eventId, slot },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject)
      })
      .catch(reject)
    })
  },

  markAsUnavailable(_, { slot, spaceId, isRecurrent, endsAt }) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/events/unavailable',
          { slot, spaceId, isRecurrent, endsAt },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject)
      })
      .catch(reject)
    })
  },

  async updateVisitConfirmation(_, { eventId, reasonId, type }) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        `/events/visit/${ type }`,
        { eventId, reasonId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  save({ getters, commit }, { usingSubscription, payload }) {
    return new Promise((resolve, reject) => {
      const index = getters.GET_EVENTS.findIndex(e => e.id === payload.id)
      if (index === -1) {
        reject('Evento não encontrado.')
        return
      }
      payload.start = firebase.firestore.Timestamp.fromDate(payload.start)
      payload.end = firebase.firestore.Timestamp.fromDate(payload.end)
      const eventData = {
        ...payload,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }
      firestoreDb.collection('events').doc(payload.id).update(eventData)
        .then(() => {
          firestoreDb.collection('events').doc(payload.id).get()
            .then((doc) => {
              const data = doc.data()
              data.start = data.start.toDate()
              data.end = data.end.toDate()
              const event = { id: doc.id, ...data }
              if (!usingSubscription) {
                commit('SET_EVENT', { index, event })
              }
              resolve(event)
            })
            .catch((error) => {
              reject(error)
            })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async fetchDoctorEvent({ getters }, id) {
    const events = getters.ALL_DOCTOR_EVENTS;
    const event = events.find((e) => e.id === id);

    if (event) {
      return event;
    }

    return firestoreDb
        .collection('events')
        .doc(id)
        .get()
        .then((doc) => doc.exists ? { id: doc.id, ...doc.data() } : null);
  },

  async fetchDoctorEvents(_, { start, end }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/events/doctor',
        {
          params: { start, end },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchOwnerEvents(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/events/owner',
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchServices(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/events/services',
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async lateBillingDetails(_, { eventId, newEnd }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/events/late-bill/details',
        {
          params: { eventId, newEnd },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },
  
  async createLateBilling(_, payload) {
    const token = await auth.currentUser.getIdToken();
    const response = await api.post(
      '/events/late-bill',
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data;
  },

  reset({ commit }) {
    commit(RESET);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

// OLD NOT REAL-TIME FETCH:
// fetch({ commit, rootGetters }, { end }) {
//   // TODO somehow use "start" too. firebase is problematic: cannot have two inequalities on different fields...
//   return new Promise((resolve, reject) => {
//     const userUid = rootGetters['user/GET_USER_UID']
//     Promise.all([
//       firestoreDb.collection('events')
//         .where('userId', '==', userUid)
//         .where('start', '<=', end)
//         .get(),
//       firestoreDb.collection('events')
//         .where('ownerId', '==', userUid)
//         .where('start', '<=', end)
//         .get()
//     ]).then(([querySnapshotAsDoctor, querySnapshotAsOwner]) => {
//         const events = []
//         querySnapshotAsDoctor.forEach((doc) => {
//           events.push({ id: doc.id, role: 'doctor', ...doc.data() })
//         })
//         querySnapshotAsOwner.forEach((doc) => {
//           events.push({ id: doc.id, role: 'owner', ...doc.data() })
//         })
//         commit('SET_EVENTS', { events })
//         resolve()
//       }).catch((error) => {
//         reject(error)
//       })
//   })
// },
