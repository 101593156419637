import Vue from 'vue'

import { auth, firebase, firestoreDb, handleDoc } from '@/lib/firebase';
import { sanitizeStr } from '@/utils/index';
import { collections } from '@/constants/collections';
import api from '@/lib/api';

export const MUTATIONS = {
  ADD_UNITY: 'ADD_UNITY',
  SET_HABITAT_PASSES: 'SET_HABITAT_PASSES',
  SET_UNITIES: 'SET_UNITIES',
  SET_SPACES: 'SET_SPACES',
  SET_DOCTOR_SPACES: 'SET_DOCTOR_SPACES',
  SET_UNITIES_LOADED: 'SET_UNITIES_LOADED',
  SET_SPACES_LOADED: 'SET_SPACES_LOADED',
  UNSUBSCRIBERS: 'SET_UNSUBSCRIBERS',
};

export const GETTERS = {
  HABITAT_PASSES: 'GET_HABITAT_PASSES',
  UNITIES: 'GET_UNITIES',
  SPACES: 'GET_SPACES',
  DOCTOR_SPACES: 'GET_DOCTOR_SPACES',
  UNITIES_LOADED: 'UNITIES_LOADED',
  SPACES_LOADED: 'SPACES_LOADED',
  UNSUBSCRIBERS: 'GET_UNSUBSCRIBERS',
};

const RESET = 'RESET';

const getDefaultState = () => ({
  unsubscribers: [],
  habitatPasses: [],
  doctorSpaces: [],
  spaces: [],
  unities: [],
  unitiesLoaded: false,
  spacesLoaded: false,
});

export const state = getDefaultState();

export const mutations = {
  [MUTATIONS.SET_SPACES](state, spaces) {
    state.spaces = spaces;
  },
  [MUTATIONS.SET_DOCTOR_SPACES](state, spaces) {
    state.doctorSpaces = spaces;
  },
  SET_SPACE(state, { index, space }) {
    Vue.set(state.spaces, index, space)
  },
  ADD_SPACE(state, { space }) {
    // state.spaces.push(space)
    // prepend for visibility
    state.spaces = [space, ...state.spaces]
  },
  REMOVE_SPACE(state, { index }) {
    state.spaces.splice(index, 1)
  },
  [MUTATIONS.SET_HABITAT_PASSES](state, passes) {
    state.habitatPasses = passes;
  },
  [MUTATIONS.SET_UNITIES](state, unities) {
    state.unities = unities;
  },
  [MUTATIONS.SET_UNITIES_LOADED](state) {
    state.unitiesLoaded = true;
  },
  [MUTATIONS.SET_SPACES_LOADED](state) {
    state.spacesLoaded = true;
  },
  [MUTATIONS.ADD_UNITY](state, unity) {
    state.unities = [ unity, ...state.unities ];
  },
  [MUTATIONS.UNSUBSCRIBERS](state, unsubscribers) {
    state.unsubscribers = unsubscribers;
  },

  [RESET](state) {
    if (state.unsubscribers) {
      state.unsubscribers.forEach((p) => p());
    }

    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  [GETTERS.HABITAT_PASSES](state) {
    return state.habitatPasses;
  },
  [GETTERS.SPACES](state) {
    return state.spaces;
  },
  [GETTERS.DOCTOR_SPACES](state) {
    return state.doctorSpaces;
  },
  [GETTERS.UNITIES](state) {
    return state.unities;
  },
  [GETTERS.UNITIES_LOADED](state) {
    return state.unitiesLoaded;
  },
  [GETTERS.SPACES_LOADED](state) {
    return state.spacesLoaded;
  },
  [GETTERS.UNSUBSCRIBERS](state) {
    return state.unsubscribers;
  }
}

export const actions = {
  async fetchResultSpace(_, {id, preview}) {
    const token = await auth.currentUser?.getIdToken();

    try {
      const response = await api.get('/space/result', {
        params: { id, preview },
        headers: token ? { Authorization: `Bearer ${token}` } : null,
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchSpace({ state, commit }, { id }) {
    let space = state.doctorSpaces.find((s) => s.id === id);

    if (space && space.cacheExpiresAt > Date.now()) {
      return space;
    }

    space = state.spaces.find((s) => s.id === id);

    if (space) {
      // Owner's space is automatically updated
      // so no need to set cache expires at
      commit(MUTATIONS.SET_DOCTOR_SPACES, [
        space,
        ...state.doctorSpaces,
      ]);
      return space;
    }

    return firestoreDb
      .collection('spaces')
      .doc(id)
      .get()
      .then((doc) => ({ id: doc.id, ...doc.data(), cacheExpiresAt: Date.now() + 1000 * 60 * 30, }))
      .then((space) => {
        const doctorSpaces = state.doctorSpaces;
        const index = doctorSpaces.findIndex((s) => s.id === space.id);

        if (index >= 0) {
          doctorSpaces[index] = space;
          commit(MUTATIONS.SET_DOCTOR_SPACES, [ ...doctorSpaces ]);
        } else {
          commit(MUTATIONS.SET_DOCTOR_SPACES, [ ...doctorSpaces, space ]);
        }

        return space;
      })
  },

  async fetchSpaceChanges(_, id) {
    return firestoreDb
      .collection('spaceChanges')
      .doc(id)
      .get()
      .then(handleDoc)
      .then((changes) => {
        const space = changes ?? { id };

        Object.entries(space.photos ?? {}).forEach((entry) => {
          if (entry[1].fullPath === space.mainPhotoFullPath) {
            entry[1].order = 0;
          }
        });

        return space;
      });
  },

  async fetchUserUsage(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/space/user/usage',
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async add(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/space/new',
        payload,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  addUnity({ rootGetters }, { file, payload }) {
    // TODO allow adding more owners to a space
    return new Promise((resolve, reject) => {
      const userUid = rootGetters['user/GET_USER_UID'];
      const data = {
        ...payload,
        ownerId: userUid,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const uploadFile = async (unityId) => {
        const filePath = `unities/${ unityId }/photos/logo`;
        const fileSnapshot = await firebase.storage().ref(filePath).put(file);
        const url = await fileSnapshot.ref.getDownloadURL();
        const fullPath = fileSnapshot.metadata.fullPath;
        await firestoreDb.collection('unities').doc(unityId).update({
          logo: { url, fullPath },
        });
      };

      firestoreDb.collection('unities').add(data)
        .then((docRef) => {
          firestoreDb.collection('unities').doc(docRef.id).get()
            .then((doc) => {
              if (file) {
                uploadFile(doc.id)
                  .then(() => resolve(docRef.id));
              } else {
                resolve(docRef.id);
              }
            })
            .catch((error) => {
              reject(error)
            })
        })
        .catch((error) => {
          reject(error)
        });
    })
  },

  async editUnity(_, { file, id, payload }) {
    // TODO allow adding more owners to a space
    const data = {
      ...payload,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    const uploadFile = async (unityId) => {
      const filePath = `unities/${ unityId }/photos/logo`;
      const fileSnapshot = await firebase.storage().ref(filePath).put(file);
      const url = await fileSnapshot.ref.getDownloadURL();
      const fullPath = fileSnapshot.metadata.fullPath;
      await firestoreDb.collection('unities').doc(unityId).update({
        logo: { url, fullPath },
      });
    };

    try {
      await firestoreDb.collection('unities').doc(id).update(data);
  
      if (file) {
        await uploadFile(id);
      }

      return id;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async unityPrivate(_, unityId) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        `/space/unity/${ unityId }/private`,
        {
          headers: { Authorization: `Bearer ${token}` },
        });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async unityProducts(_, unityId) {
    return firestoreDb
      .collection(collections.unityProducts)
      .doc(unityId)
      .get()
      .then(handleDoc);
  },

  delete({ getters, commit }, { spaceId }) {
    return new Promise((resolve, reject) => {
      const index = getters.GET_SPACES.findIndex(e => e.id === spaceId)
      if (index === -1) {
        reject('Consultório não encontrado.')
        return
      }
      firestoreDb.collection('spaces').doc(spaceId).delete()
        .then(() => {
          commit('REMOVE_SPACE', { index })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async save({ getters, commit }, payload) {
    const spaces = getters[GETTERS.SPACES] || [];
    const index = getters[GETTERS.SPACES].findIndex(e => e.id === payload.id)

    if (index === -1) {
      throw new Error('Consultório não encontrado');
    }

    const data = {
      ...payload,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    await firestoreDb
      .collection(collections.spaces)
      .doc(payload.id)
      .update(data);

    spaces[index] = {
      ...spaces[index],
      ...data,
    };

    commit(MUTATIONS.SET_SPACES, [ ...spaces ]);
  },

  async saveChanges(_, { id, payload }) {
    const data = {
      ...payload,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    const doc = await firestoreDb
      .collection(collections.spaceChanges)
      .doc(id)
      .get();

    if (!doc.exists) {
      return firestoreDb
        .collection(collections.spaceChanges)
        .doc(id)
        .set(data);
    }

    return firestoreDb
      .collection(collections.spaceChanges)
      .doc(id)
      .update(data);
  },

  async hasActiveSubscriptions(_, spaceId) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/space/has-active-subscriptions',
        {
          params: { spaceId },
          headers: { Authorization: `Bearer ${token}` },
        });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  searchSpaces({ rootGetters }, { name }) {
    return new Promise((resolve, reject) => {
      const userUid = rootGetters['user/GET_USER_UID'];
      firestoreDb.collection('spaces')
        .where('ownerId', '==', userUid)
        .get()
        .then((querySnapshot) => {
          const spaces = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();

            if (data.name && sanitizeStr(data.name).includes(sanitizeStr(name))) {
              spaces.push({ id: doc.id, ...doc.data() });
            }
          });

          resolve(spaces);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async fetchUnitySummary(_, unityId) {
    try {
      const response = await api.get('/space/unity-summary', { params: { unityId } });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async spaceSpecs(_, spaceIds) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/space/specs',
        {
          params: { spaceIds },
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async updateUserRegularization(_, { userId, spaceId, document, isRegular }) {
    return firestoreDb
      .collection('spaces')
      .doc(spaceId)
      .collection('usage')
      .doc(userId)
      .set({
        [document]: isRegular,
      }, { merge: true });
  },

  async saveSharedHourPackages({ dispatch }, options) {
    const {
      ownerId,
      unityId,
      spaceId,
      sharedSpaces,
      shareHourPackagesAll,
      shareHourPackages,
      hasPackagePrice,
    } = options;
    const products = (await dispatch('unityProducts', unityId)) || {};
    let hourPackages = products.hourPackages || {};

    // If no option is enabled, or hour package is disabled
    if (!shareHourPackagesAll && !shareHourPackages || !hasPackagePrice) {
      // And previous main was this space
      if (hourPackages.main === spaceId) {
        // Remove it as main
        delete hourPackages.main;
      }

      // And remove it as reference to others
      delete hourPackages[spaceId];
    }
    // If all is enabled
    else if (shareHourPackagesAll) {
      // And there is another main, throw
      if (hourPackages.main && hourPackages.main !== spaceId) {
        throw Error('Outro consultório já está compartilhando com todos');
      }

      // Otherwise, save this space as main
      hourPackages.main = spaceId;

      // But remove the reference to others
      delete hourPackages[spaceId];
    }
    // If as reference to others
    else if (shareHourPackages) {
      // If previosly was main
      if (hourPackages.main === spaceId) {
        // Remove it
        delete hourPackages.main;
      }

      // Add as reference
      hourPackages[spaceId] = sharedSpaces;
    }

    return firestoreDb
      .collection(collections.unityProducts)
      .doc(unityId)
      .set({
        ...products,
        ownerId,
        hourPackages,
      });
  },

  async sharedHourPackages(_, spaceId) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/space/shared/hour-packages',
        {
          params: { spaceId },
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async hourPackageForSpace(_, spaceId) {
    try {
      const response = await api.get(
        '/space/hour-package',
        {
          params: { spaceId },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  unsubscribeAll({ getters, commit }) {
    const prevUnsubscribers = getters.GET_UNSUBSCRIBERS;
    if (prevUnsubscribers && prevUnsubscribers.length > 0) {
      prevUnsubscribers.forEach((p) => p());
      commit(MUTATIONS.UNSUBSCRIBERS, null);
    }
  },

  subscriberAll({ dispatch, commit, getters }, { ownerId }) {
    // reset previous unsubscribers if any
    dispatch('unsubscribeAll');

    let queries = {
      spaces: firestoreDb
        .collection('spaces')
        .where('ownerId', '==', ownerId),
      unities: firestoreDb
        .collection('unities')
        .where('ownerId', '==', ownerId),
    };

    const unsubscribers = [];

    for (const key of Object.keys(queries)) {
      const query = queries[key];
      const unsubscriber = query
        .onSnapshot((snapshot) => {
          const onChange = (data, type) => {
            if (key === 'spaces') {
              const spaces = getters[GETTERS.SPACES] || [];
              if (type === 'modified') {
                console.log('space modified')
                const index = spaces.findIndex((s) => s.id === data.id);

                if (index < 0) { 
                  console.warn('Space modified without being cached');
                  return;
                }

                spaces[index] = data;
                commit(MUTATIONS.SET_SPACES, [ ...spaces ]);
              } else if (type === 'added') {
                const index = spaces.findIndex((s) => s.id === data.id);

                if (index >= 0) {
                  spaces[index] = data;
                  commit(MUTATIONS.SET_SPACES, [ ...spaces ]);
                  return;
                }

                commit(MUTATIONS.SET_SPACES, [ ...spaces, data ]);
              }
            } else if (key === 'unities') {
              const unities = getters[GETTERS.UNITIES] || [];
              if (type === 'modified') {
                console.log('Unity modified')
                const index = unities.findIndex((u) => u.id === data.id);

                if (index < 0) { 
                  console.warn('Unity modified without being cached');
                  return;
                }

                unities[index] = data;
                commit(MUTATIONS.SET_UNITIES, [ ...unities ]);
              } else if (type === 'added') {
                console.log('Unity added')
                const unityIndex = unities.findIndex((u) => u.id === data.id);

                if (unityIndex >= 0) {
                  unities[unityIndex] = data;
                  commit(MUTATIONS.SET_UNITIES, [ ...unities ]);
                  return;
                }

                commit(MUTATIONS.SET_UNITIES, [ ...unities, data ]);
              }
            }
          };

          if (snapshot.docChanges) {
            snapshot
              .docChanges()
              .forEach((change) => {
                onChange({ id: change.doc.id, ...change.doc.data() }, change.type);
              });
          } else {
            onChange(snapshot.data());
          }

          if (key === 'unities') {
            commit(MUTATIONS.SET_UNITIES_LOADED);
          } else if (key === 'spaces') {
            commit(MUTATIONS.SET_SPACES_LOADED);
          }
        })
        unsubscribers.push(unsubscriber);
    }

    commit(MUTATIONS.UNSUBSCRIBERS, unsubscribers);
  },

  reset({ commit, dispatch }) {
    dispatch('unsubscribeAll');
    commit(RESET);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
