const DEFAULT_TZ = 'America/Sao_Paulo';

const getDate = (date) => {
  return date?.toDate ? date.toDate() : date;
}

class DateHelper {
  constructor() {
    this.dayjs = require('dayjs');
    require('dayjs/locale/pt-br');

    this.checkTimezone();
  }

  checkTimezone() {
    if (this.timezoneAdded) { return; }

    const utc = require('dayjs/plugin/utc');
    const timezone = require('dayjs/plugin/timezone');
    const localizedFormat = require('dayjs/plugin/localizedFormat');
    const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
    const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
    const duration = require('dayjs/plugin/duration');
    const minMax = require('dayjs/plugin/minMax');
    const isBetween = require('dayjs/plugin/isBetween');

    this.dayjs.extend(localizedFormat);
    this.dayjs.extend(timezone);
    this.dayjs.extend(utc);
    this.dayjs.extend(isSameOrBefore);
    this.dayjs.extend(isSameOrAfter);
    this.dayjs.extend(duration);
    this.dayjs.extend(minMax);
    this.dayjs.extend(isBetween);
    this.dayjs.tz.setDefault(DEFAULT_TZ);
    this.defaultTz = DEFAULT_TZ;
  }

  now(TZ = DEFAULT_TZ) {
    return this.dayjs().tz(TZ);
  }

  nowDate() {
    return this.now().toDate();
  }

  date(date, TZ = DEFAULT_TZ, format) {
    return this.dayjs(getDate(date), format).tz(TZ);
  }

  dateOnTime(date, TZ = DEFAULT_TZ, format) {
    return this.dayjs(getDate(date), format).tz(TZ, true);
  }

  dateOnTz(date, TZ = DEFAULT_TZ) {
    return this.dayjs.tz(getDate(date), TZ);
  }

  isSame(date1, date2, unity = 'day') {
    return date1.isSame(date2, unity);
  }

  isAfter(date1, date2) {
    return date1.isAfter(date2);
  }

  isAfterNow(date) {
    return date.isAfter(this.now());
  }

  isSameOrAfter(date1, date2) {
    return date1.isSameOrAfter(date2);
  }

  isSameOrAfterNow(date) {
    return date.isSameOrAfter(this.now());
  }

  isBefore(date1, date2) {
    return date1.isBefore(date2);
  }

  isBeforeNow(date) {
    return this.date(date).isBefore(this.now());
  }

  isSameOrBefore(date1, date2) {
    return date1.isSameOrBefore(date2);
  }

  isSameOrBeforeNow(date) {
    return date.isSameOrBefore(this.now());
  }

  prettyFormat(date, format = 'LLLL') {
    return date.locale('pt-br').format(format);
  }

  diff(start, end, unity) {
    return end.diff(start, unity);
  }

  duration(start, end) {
    if (typeof end === 'string') {
      return this.dayjs.duration(start, end);
    }

    return this.dayjs.duration(end.diff(start));
  }

  max(...dates) {
    return this.dayjs.max(dates);
  }

}

export default new DateHelper();
