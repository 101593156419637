<template>
  <v-dialog v-model="isShowing" content-class="max-w-xl flex h-5/6 bg-white" :persisten="isLoading" @click:outside="onCancel">
    <div class="flex-1 flex items-center justify-center" v-if="isLoading">
      <v-progress-circular color="primary" indeterminate />
    </div>

    <survey :data="survey" :on-close="onCancel" no-gutters center flat small v-else-if="survey" />
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

import Survey from '@/components/Survey.vue';

export default {
  name: 'SurveyDialog',

  components: {
    Survey,
  },

  props: {
    show: {
      type: Boolean,
      required: true,
    },
  
    onCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
      survey: null,
    };
  },

  computed: {
    ...mapGetters('surveys', { surveys: 'SURVEYS' }),

    isShowing() {
      return this.show;
    },
  },

  methods: {
    async fetch() {
      this.isLoading = true;

      try {
        this.survey = await this.$store.dispatch('surveys/fetchSurvey', { id: this.surveys[0].id });
      } catch (error) {
        this.$toasted.global.custom_error({ message: error.message || 'Não foi possível carregar a pesquisa' });
        this.onCancel();
      }

      this.isLoading = false;
    },
  },

  watch: {
    show: {
      immediate: true,
      handler(value) {
        if (value) {
          this.fetch();
        }
      },
    },
  },
}
</script>
