import { render, staticRenderFns } from "./TermsOfUseDialog.vue?vue&type=template&id=e0fcd9dc&scoped=true"
import script from "./TermsOfUseDialog.vue?vue&type=script&lang=js"
export * from "./TermsOfUseDialog.vue?vue&type=script&lang=js"
import style0 from "./TermsOfUseDialog.vue?vue&type=style&index=0&id=e0fcd9dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0fcd9dc",
  null
  
)

export default component.exports