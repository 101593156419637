import dayjs from 'dayjs';

import { auth, firestoreDb, handleDoc, handleSnapshot } from '@/lib/firebase';
import { collections } from '@/constants/collections';
import api from '@/lib/api';

export const MUTATIONS = {
  DATA_SHARE_REQUESTS: 'SET_DATA_SHARE_REQUESTS',
  INTERNAL_MESSAGES: 'SET_INTERNAL_MESSAGES',
  MESSAGE_UNSUBSCRIBER: 'SET_MESSAGE_UNSUBSCRIBER',
  PENDING_ACCESS_REQUESTS: 'SET_PENDING_ACCESS_REQUESTS',
  PENDING_SERVICES_REQUESTS: 'SET_PENDING_SERVICES_REQUESTS',
  SET_LAST_USER: 'SET_LAST_USER',
  UNSUBSCRIBERS: 'SET_UNSUBSCRIBERS',
};

export const GETTERS = {
  DATA_SHARE_REQUESTS: 'GET_DATA_SHARE_REQUESTS',
  INTERNAL_MESSAGES: 'GET_INTERNAL_MESSAGES',
  MESSAGE_UNSUBSCRIBER: 'GET_MESSAGE_UNSUBSCRIBER',
  PENDING_ACCESS_REQUESTS: 'GET_PENDING_ACCESS_REQUESTS',
  PENDING_SERVICES_REQUESTS: 'GET_PENDING_SERVICES_REQUESTS',
  UNSUBSCRIBERS: 'GET_UNSUBSCRIBERS',
};

const RESET = 'RESET';

const getDefaultState = () => ({
  dataShareRequests: [],
  internalMessages: [],
  pendingAccessRequests: 0,
  pendingServicesRequests: 0,
  messageUnsubscriber: null,
  unsubscribers: [],
  lastUser: [],
});

export const state = getDefaultState();

export const mutations = {
  [MUTATIONS.DATA_SHARE_REQUESTS](state, data) {
    state.dataShareRequests = data;
  },
  [MUTATIONS.INTERNAL_MESSAGES](state, data) {
    state.internalMessages = data;
  },
  [MUTATIONS.MESSAGE_UNSUBSCRIBER](state, data) {
    state.messageUnsubscriber = data;
  },
  [MUTATIONS.UNSUBSCRIBERS](state, data) {
    state.unsubscribers = data;
  },
  [MUTATIONS.PENDING_ACCESS_REQUESTS](state, data) {
    state.pendingAccessRequests = data;
  },
  [MUTATIONS.PENDING_SERVICES_REQUESTS](state, data) {
    state.pendingServicesRequests = data;
  },
  [MUTATIONS.SET_LAST_USER](state, user) {
    state.lastUser = { ...user };
  },

  [RESET](state) {
    if (state.unsubscribers) {
      state.unsubscribers.forEach((p) => p());
    }

    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  [GETTERS.DATA_SHARE_REQUESTS](state) {
    return state.dataShareRequests;
  },
  [GETTERS.INTERNAL_MESSAGES](state) {
    return state.internalMessages;
  },
  [GETTERS.MESSAGE_UNSUBSCRIBER](state) {
    return state.messageUnsubscriber;
  },
  [GETTERS.PENDING_ACCESS_REQUESTS](state) {
    return state.pendingAccessRequests;
  },
  [GETTERS.PENDING_SERVICES_REQUESTS](state) {
    return state.pendingServicesRequests;
  },
  [GETTERS.UNSUBSCRIBERS](state) {
    return state.unsubscribers;
  },
}

export const actions = {
  async fetchPlans() {
    try {
      const response = await api.get('/saas/plans');

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      throw error;
    }
  },

  async getSaas() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get('/saas', {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      throw error;
    }
  },

  async getRestoreDetails() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get('/saas/restore-details', {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      throw error;
    }
  },

  async startSaas(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const formData = new FormData();
      formData.append('logo', payload.logo);
      formData.append('data', JSON.stringify(payload.data));

      const response = await api.post('/saas', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      throw error;
    }
  },

  async updateSaas(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const formData = new FormData();
      formData.append('logo', payload.logo);
      formData.append('data', JSON.stringify(payload.data));

      const response = await api.put('/saas', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      throw error;
    }
  },

  async deleteSaas() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.delete('/saas',
      {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      throw error;
    }
  },

  async checkUser(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/saas/user',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async checkUserEmail(_, email) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/saas/user/email',
        { email },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async importUsers(_, { file }) {
    const token = await auth.currentUser.getIdToken();
    
    const formData = new FormData();
    formData.append('file', file);

    try {
      await api.post(
        'saas/users/import',
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async enableUser(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/saas/user/enable',
        params,
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async disableUser(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/saas/user/disable',
        params,
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async approveAccessRequest(_, id) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/saas/user/access/approve',
        { id },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async rejectAccessRequest(_, id) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/saas/user/access/reject',
        { id },
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async addUser(_, { files, specFiles, userInfo }) {
    const token = await auth.currentUser.getIdToken();
    let newUserId;

    try {
      const response = await api.post(
        '/saas/user/new',
        userInfo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      newUserId = response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }

    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('userId', newUserId);

      try {
        await api.post(
          '/saas/user/file',
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );
      } catch (error) {
        if (error.response) {
          throw error.response.data;
        }

        throw error;
      }
    }

    for (const file of specFiles) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('userId', newUserId);
      formData.append('isSpec', true);

      try {
        await api.post(
          '/saas/user/file',
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );
      } catch (error) {
        if (error.response) {
          throw error.response.data;
        }

        throw error;
      }
    }

    try {
      const docs = files.map((file) => file.name);
      const specDocs = specFiles.map((file) => file.name);

      await api.post(
        '/saas/user/validate',
        {
          userId: newUserId,
          docs,
          specDocs,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async addTeamMember(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/saas/team-member',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchUsers() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get('/saas/users', {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      throw error;
    }
  },

  async fetchTeam() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get('/saas/team', {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      throw error;
    }
  },

  async fetchUser({ commit, state }, { id, useCache = false }) {
    if (useCache &&
        state.lastUser?.id === id
        && state.lastUser.cacheUntil > dayjs().toDate()) {
      return state.lastUser;
    }

    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/saas/user',
        {
          params: { id },
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      commit(MUTATIONS.SET_LAST_USER, {
        ...response.data,
        cacheUntil: dayjs().add(5, 'minute').toDate(), // local cache
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchEvents(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/saas/events',
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchUserEvents(_, { userId, start, end }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/saas/user/events',
        {
          params: { userId, start, end },
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchOwnerEvents(_, { start, end }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/saas/owner/events',
        {
          params: { start, end },
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchUnities(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/saas/unities',
        { params, headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchUnity(_, id) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/saas/unity',
        {
          params: { id },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async addUnity(_, { file, payload }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('data', JSON.stringify(payload));

      const response = await api.post(
        '/saas/unity',
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async updateUnity(_, { file, id, payload }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('data', JSON.stringify(payload));

      const response = await api.put(
        '/saas/unity',
        formData,
        {
          params: { id },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchUnitySpaces(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/saas/unity/spaces',
        { params, headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchOwnerSpaces() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/saas/spaces',
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchSpaceChanges(_, id) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/saas/space/changes',
        {
          params: { id },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchInternalMessages() {
    const token = await auth.currentUser?.getIdToken();

    if (!token) {
      return [];
    }

    try {
      const response = await api.get(
        '/saas/messages',
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async markInternalMessageViewed(_, messageId) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/saas/message/viewed',
        {
          messageId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async addSpace(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/saas/space',
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async updateSpace(_, { spaceId, payload }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.put(
        '/saas/space',
        payload,
        {
          params: { spaceId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async updateSpaceChanges(_, { spaceId, payload }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.put(
        '/saas/space/changes',
        payload,
        {
          params: { spaceId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async spaceHasActiveSubscriptions(_, spaceId) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/saas/space/has-active-subscriptions',
        {
          params: { spaceId },
          headers: { Authorization: `Bearer ${token}` },
        });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async createTransaction(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/saas/transaction',
        payload,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async updateTransaction(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.put(
        '/saas/transaction',
        payload,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async addProduct(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/saas/add/product',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchProduct(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/saas/product',
        { params: payload, headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async saveService(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const formData = new FormData();
      formData.append('term', payload.term);
      formData.append('data', JSON.stringify(payload.data));

      const isUpdate = !!payload.id;
      const method = isUpdate ? api.put : api.post;
      const response = await method(
        isUpdate ? `/saas/services/${ payload.id }` : '/saas/services',
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
          'Content-Type': 'multipart/form-data',
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async deleteService(_, payload) {
    const token = await auth.currentUser.getIdToken();
    
    try {
      const response = await api.delete(
        '/saas/services',
        { 
          params: payload,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchServicesRequests(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/saas/services/requests',
        { params: payload, headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchServicesRequest(_, id) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        `/saas/services/request/${ id }`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchServicesUsage(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/saas/services/usage',
        { params: payload, headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchLooseServices(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/saas/services/loose',
        { params: payload, headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchSpecializations(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        'saas/users/specs',
        {
          params,
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchUsersNames(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        'saas/users/names',
        {
          params,
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async approveServiceRequest(_, requestId) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/saas/services/request/approve',
        { requestId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      
      throw error;
    }
  },

  async rejectServiceRequest(_, requestId) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/saas/services/request/reject',
        { requestId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      
      throw error;
    }
  },

  async fetchServices(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        `/saas/services`,
        {
          params,
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async signUp(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/saas/signup',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      
      throw error;
    }
  },

  async getSubscriptionTransactions() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get('/saas/subscription/transactions', {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      throw error;
    }
  },

  unsubscribeAll({ getters, commit }) {
    const prevUnsubscribers = getters[GETTERS.UNSUBSCRIBERS];

    if (prevUnsubscribers && prevUnsubscribers.length > 0) {
      prevUnsubscribers.forEach((p) => p());
      commit(MUTATIONS.UNSUBSCRIBERS, []);
    }
  },

  unsubscribeMessages({ getters, commit }) {
    const unsubscriber = getters[GETTERS.MESSAGE_UNSUBSCRIBER];

    if (unsubscriber) {
      unsubscriber();
    }

    commit(MUTATIONS.MESSAGE_UNSUBSCRIBER, null);
  },

  subscriberAll({ dispatch, commit, rootGetters }, saasId) {
    dispatch('unsubscribeAll');

    const userUid = rootGetters['user/GET_USER_UID'];

    const accessRequestsQuery = () => {
      return firestoreDb
        .collectionGroup(collections.saasUsers)
        .where('saasId', '==', saasId)
        .where('status', '==', 'access-request');
    };

    const serviceRequestsQuery = () => {
      let query = firestoreDb
        .collection(collections.servicesRequests);

      if (saasId) {
        query = query.where('saasId', '==', saasId);
      } else {
        query = query.where('ownerId', '==', userUid);
      }

      return query.where('status', '==', 'pending');
    };
    
    const queries = {
      'regular': firestoreDb
        .collectionGroup(collections.saasUsers)
        .where('userId', '==', userUid)
        .where('status', '==', 'pending')
        .onSnapshot((snapshot) => {
          commit(MUTATIONS.DATA_SHARE_REQUESTS, handleSnapshot(snapshot));
        }),
      'access-requests': saasId ? accessRequestsQuery()
        .onSnapshot((snapshot) => {
          commit(MUTATIONS.PENDING_ACCESS_REQUESTS, handleSnapshot(snapshot).length);
        }) : null,
      'services-requests': serviceRequestsQuery()
        .onSnapshot((snapshot) => {
          commit(MUTATIONS.PENDING_SERVICES_REQUESTS, handleSnapshot(snapshot).length);
        }),
    };
    const unsubscribers = [];

    for (const key of Object.keys(queries)) {
      const query = queries[key];

      if (!query) {
        continue;
      }

      unsubscribers.push(query);
    };

    commit(MUTATIONS.UNSUBSCRIBERS, unsubscribers);
  },

  subscribeMessages({ commit, getters }, { saasId, onMessage }) {
    const prevUnsubscribers = getters[GETTERS.UNSUBSCRIBERS];
    const internalMessagesUnsubscriber = prevUnsubscribers?.find(({ key }) => key === 'internal-messages');

    if (internalMessagesUnsubscriber) {
      internalMessagesUnsubscriber.unsubscriber();
    }

    commit(MUTATIONS.MESSAGE_UNSUBSCRIBER, null);

    if (!saasId) {
      return;
    }

    const unsubscriber = firestoreDb
      .collection(collections.messages)
      .where('type', '==', 'internal')
      .where('saasId', '==', saasId)
      .orderBy('createdAt', 'asc')
      .startAt(dayjs().toDate())
      .onSnapshot((snapshot) => {
        const onChange = (data, type) => {
          const messages = getters[GETTERS.INTERNAL_MESSAGES] || [];

          if (type === 'added') {
            onMessage(data);
            commit(MUTATIONS.INTERNAL_MESSAGES, [ data, ...messages]);
          } else if (type === undefined) {
            commit(MUTATIONS.INTERNAL_MESSAGES, [ ...data, ...messages]);
          }
        };

        if (snapshot.docChanges) {
          snapshot
            .docChanges()
            .forEach((change) => {
              onChange(handleDoc(change.doc), change.type);
            });
        } else {
          onChange(handleSnapshot(snapshot));
        }
      });

    commit(MUTATIONS.MESSAGE_UNSUBSCRIBER, unsubscriber);
  },

  reset({ commit, dispatch }) {
    dispatch('unsubscribeAll');
    dispatch('unsubscribeMessages');
    commit(RESET);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
