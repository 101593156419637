import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/pt-br';

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(duration);

const MIN_PASSWORD_LENGTH = 8;
const MINOR_AGE = 17;

const thousandSeparator = new Intl.NumberFormat('pt-BR').format(1111).replace(/1/g, '');
const decimalSeparator = new Intl.NumberFormat('pt-BR').format(1.1).replace(/1/g, '');

const isValidCpf = (cpf) => {
  cpf = cpf ? cpf.replace(/\D/g, '') : '';

  if (cpf.length != 11 || /^(\d)\1{10}$/.test(cpf)) { return 'CPF inválido'; }

  let result = true;

  [ 9, 10 ].forEach((j) => {
    let soma = 0;
    let r;

    cpf
      .split(/(?=)/)
      .splice(0, j)
      .forEach((e, i) => {
        soma += parseInt(e) * ((j + 2) - (i + 1));
      });

    r = soma % 11;
    r = (r <2)?0:11-r;

    if (r != cpf.substring(j, j + 1)) { result = false; }
  });

  return result || 'CPF inválido';
};

const isValidCnpj = (input) => {
  if (!input) { return 'CNPJ inválido'; }

  const cnpj = input.replace(/[^\d]+/g,'');

  if (cnpj == '' && cnpj.length != 14) { return 'CNPJ inválido'; }

  if (cnpj == "00000000000000" || 
      cnpj == "11111111111111" || 
      cnpj == "22222222222222" || 
      cnpj == "33333333333333" || 
      cnpj == "44444444444444" || 
      cnpj == "55555555555555" || 
      cnpj == "66666666666666" || 
      cnpj == "77777777777777" || 
      cnpj == "88888888888888" || 
      cnpj == "99999999999999") { return 'CNPJ inválido'; }
       
  let length = cnpj.length - 2;
  let number = cnpj.substring(0, length);
  let pos = length - 7;
  const digits = cnpj.substring(length);

  const getSum = () => {
    let sum = 0;

    for (let i = length; i >= 1; i--) {
      sum += number.charAt(length - i) * pos--;
      if (pos < 2) { pos = 9; }
    }

    return sum;
  }

  const result = () => {
    const sum = getSum();
    return sum % 11 < 2 ? 0 : 11 - sum % 11;
  }

  if (result() != digits.charAt(0)) { return 'CNPJ inválido'; }
       
  length = length + 1;
  number = cnpj.substring(0, length);
  pos = length - 7;

  if (result() != digits.charAt(1)) { return 'CNPJ inválido'; }
         
  return true;
}

const rules = {
  required: value => {
    const hasValue = () => {
      if (value !== undefined && value !== null) {
        if (typeof value === 'string') {
          return !!value.trim();
        } else if (typeof value === 'number') {
          return true;
        } else if (Array.isArray(value)) {
          return !!value.length;
        }

        return !!value;
      }
    }

    return hasValue() || 'Obrigatório';
  },
  isValidCpf,
  isValidCnpj,
  length: len => v => (v || '').length <= len || `Máximo de ${len} caracteres`,
  arrayLength: (len) => (v) => (v || []).length <= len || `Máximo de ${len} items`,
  isnum: v => /^\d+$/.test(v) || `Apenas dígitos são permitidos`,
  imgEachSize: maxSizeMb => arr => {
    const array = arr ? (Array.isArray(arr) ? arr : [ arr ]) : [];
    return array.every(v => v.size < maxSizeMb * 1000000) || `Imagem deve ter tamanho inferior a ${maxSizeMb} Mb`
  },
  maxValue: (max, hint) => value => (!value || value <= max) || hint,
  minValue: (min, hint) => value => (!value || value >= min) || hint,
  currencyMinValue: (min, hint) => value => {
    if (!value) { return hint; }

    if (typeof value === 'string') {
      const currency = value
        .replace(thousandSeparator, '')
        .replace(decimalSeparator, '.')
        .replace(/[^0-9.]/g, '');
      return parseFloat(currency) >= min || hint;
    }

    return value >= min || hint;
  },
  minPasswordLength: value => (!value || value.length >= MIN_PASSWORD_LENGTH) || 'Senha curta demais',
  minCleanPhoneLength: value => {
    if (!value) {
      return true
    }
    const cleanPhone = value.replace(/[^\d]/gi, '')
    return (cleanPhone.length >= 10 && cleanPhone.length <= 11) || 'Número inválido'
  },
  email: value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'Email inválido'
  },
  isMinor: (value) => {
    const input = dayjs(value, 'DD/MM/YYYY');
    return dayjs().diff(input, 'year') > MINOR_AGE || `Usuário deve ser maior de 18 anos`;
  },
  date: (value) => {
    return value.length === 10 && dayjs(value, 'DD/MM/YYYY').isValid() || 'Data inválida';
  },
}

export { rules, MIN_PASSWORD_LENGTH }
