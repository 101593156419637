<template>
  <v-dialog v-model="isShowing" max-width="800px" content-class="flex h-5/6" :persistent="!closeable" @click:outside="closeable && onCancel">
    <v-card class="flex flex-column">
      <strong class="text-xl pl-4 pt-4 pr-4 mb-2" v-if="!closeable">Atualização dos Termos de Uso</strong>
      
      <iframe class="pl-4 pr-4 pb-4" :src="currentTerm && currentTerm.url" style="height: 100%; width: 100%"></iframe>
      <div class="flex flex-none">
        <v-spacer />

        <v-btn @click="onCancel" text v-if="closeable">Fechar</v-btn>
        <v-btn class="secondary--text font-semibold" @click="onAccept" :loading="isAccepting" text v-if="!closeable">Aceitar</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TermsOfUseComponent',

  props: {
    show: {
      type: Boolean,
      required: true,
    },
  
    onCancel: {
      type: Function,
      required: true,
    },

    closeable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isAccepting: false,
    };
  },

  computed: {
    ...mapGetters('termsOfUse', { currentTerm: 'CURRENT' }),

    isShowing() {
      return this.show;
    },
  },

  methods: {
    async onAccept() {
      this.isAccepting = true;
      try {
        await this.$store.dispatch('termsOfUse/accept');
      } catch {
        this.$toasted.global.custom_error({ message: 'Erro ao aceitar os termos. Tente novamente ou contate o suporte' })
      }
      this.isAccepting = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  margin-top: 1.5rem;
  margin-bottom: 0.2rem;
  font-weight: 600;
  font-size: 1.25rem;
  color: $secondary;
}

p {
  line-height: 1.6rem;
}

.list {
  list-style-type: upper-roman;
}

.list-a {
  list-style-type: lower-alpha;
}

li {
  padding-left: 36px;
}
</style>