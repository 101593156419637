import Vue from 'vue';
import { auth, firestoreDb, handleSnapshot } from '@/lib/firebase';
import api from '@/lib/api';

const SET_IS_PAYING = 'SET_IS_PAYING';
const GET_IS_PAYING = 'GET_IS_PAYING';
const SET_HOUR_PACKAGES = 'SET_HOUR_PACKAGES';
const GET_HOUR_PACKAGES = 'GET_HOUR_PACKAGES';
const SET_UNSUBSCRIBERS = 'SET_UNSUBSCRIBERS';
const GET_UNSUBSCRIBERS = 'GET_UNSUBSCRIBERS';

const RESET = 'RESET';

const getDefaultState = () => ({
  isPaying: false,
  currentHourPackageSubscription: 0,
  hourPackages: [],
  unsubscribers: {
    'hour-package': [],
  },
});

export const state = getDefaultState();

export const mutations = {
  [SET_IS_PAYING](state, isPaying) {
    state.isPaying = isPaying
  },
  [SET_HOUR_PACKAGES](state, hourPackages) {
    state.hourPackages = [ ...hourPackages ];
  },
  [SET_UNSUBSCRIBERS](state, { type, unsubscribers }) {
    Vue.set(state.unsubscribers, type, unsubscribers);
  },

  [RESET](state) {
    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  [GET_IS_PAYING](state) {
    return state.isPaying
  },
  [GET_HOUR_PACKAGES](state) {
    return state.hourPackages;
  },
  [GET_UNSUBSCRIBERS](state) {
    return state.unsubscribers;
  }
}

export const actions = {
  async purchase({ commit }, payload) {
    const path = () => {
      if (payload.product === 'hour-package') {
        if (payload.hasEnoughRemainingHours) {
          return '/products/package/use';
        }

        return '/products/package/purchase';
      } else if (payload.product === 'loose') {
        return '/products/loose';
      } else if (payload.product === 'monthly') {
        return '/products/monthly';
      } else if (payload.product === 'integral') {
        return '/products/integral';
      } else if (payload.product === 'habitat-pass') {
        return '/products/habitat-pass';
      }
    };

    commit(SET_IS_PAYING, true)

    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        path(),
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    } finally {
      commit(SET_IS_PAYING, false);
    }
  },

  async purchaseOrder(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/products/order/purchase',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async refuseOrder(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/products/order/refuse',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  cancel(_, payload) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        const path = () => {
          if (payload.product === 'hour-package') {
            return '/products/package/cancel';
          } else if (payload.product === 'loose') {
            return '/products/loose/cancel';
          } else if (payload.product === 'monthly') {
            return '/products/monthly/cancel';
          } else if (payload.product === 'integral') {
            return '/products/integral/cancel';
          } else if (payload.product === 'habitat-pass') {
            return '/products/habitat-pass/cancel';
          }
        }

        api.post(
          path(),
          payload,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => resolve(response.data))
        .catch(reject);
      })
      .catch(reject);
    });
  },

  modify(_, payload) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        const path = () => {
          if (payload.product === 'habitat-pass') {
            return '/products/habitat-pass/modify';
          }
        }

        api.post(
          path(),
          payload,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => resolve(response.data))
        .catch(reject);
      })
      .catch(reject);
    });
  },

  remainingPackageHours(_, payload) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.get(
          '/products/package/remaining-hours',
          {
            params: payload,
            headers: { Authorization: `Bearer ${ token }` },
          },
        )
        .then((response) => resolve(response.data))
        .catch(reject);
      })
      .catch(reject);
    });
  },

  async hourPackageDetails(_, { spaceId }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/products/package/details',
        {
          params: { spaceId },
          headers: { Authorization: `Bearer ${ token }` },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async purchaseServices(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/products/purchase/services',
        params,
        {
          headers: { Authorization: `Bearer ${ token }` },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async looseDetails(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/products/loose/details',
        {
          params,
          headers: { Authorization: `Bearer ${ token }` },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  monthlyDetails(_, { spaceId, start }) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.get(
          '/products/monthly/details',
          {
            params: { spaceId, start },
            headers: { Authorization: `Bearer ${ token }` },
          },
        )
        .then((response) => resolve(response.data))
        .catch(reject);
      })
      .catch(reject);
    });
  },

  integralDetails(_, { spaceId, start }) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.get(
          '/products/integral/details',
          {
            params: { spaceId, start },
            headers: { Authorization: `Bearer ${ token }` },
          },
        )
        .then((response) => resolve(response.data))
        .catch(reject);
      })
      .catch(reject);
    });
  },

  habitatPassDetails(_, { eventId, spaceId, slot }) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.get(
          '/products/habitat-pass/details',
          {
            params: { eventId, spaceId, slot },
            headers: { Authorization: `Bearer ${ token }` },
          },
        )
        .then((response) => resolve(response.data))
        .catch(reject);
      })
      .catch(reject);
    });
  },

  async looseCancelDetails(_, id) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/products/loose/cancel/details',
        {
          params: { id },
          headers: { Authorization: `Bearer ${ token }` },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async integralCancelDetails(_, productId) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/products/integral/cancel/details',
        {
          params: { productId },
          headers: { Authorization: `Bearer ${ token }` },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async ownerContracts(_, { spaceId, unityId }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/products/owner-contracts',
        {
          params: { spaceId, unityId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async monthlyPackage(_, id) {
    return await firestoreDb
      .collection('monthly-packages')
      .doc(id)
      .get()
      .then((doc) => doc.exists ? ({ id, ...doc.data() }) : null);
  },

  async habitatPassPackage(_, id) {
    return await firestoreDb
      .collection('habitat-pass-package')
      .doc(id)
      .get()
      .then((doc) => doc.exists ? ({ id, ...doc.data() }) : null);
  },

  async fetchMonthlyIntegralRequests(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/products/integral/requests',
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async approveMonthlyIntegralRequest(_, { requestId, useDefaultCard }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/products/integral/request/approve',
        { requestId, useDefaultCard },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async denyMonthlyIntegralRequest(_, { requestId, reason }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/products/integral/request/deny',
        { requestId, reason },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchHourPackages(_, userId) {
    return await firestoreDb.collection('hour-packages')
      .where('userId', '==', userId)
      .orderBy('createdAt', 'asc')
      .get()
      .then(handleSnapshot);
  },

  async updateHourPackage(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.put(
        '/products/hour-package',
        payload,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async subscribeHourPackages({ state, commit, rootGetters }) {
    if (state.unsubscribers['hour-package'].length !== 0) { return; }

    const userUid = rootGetters['user/GET_USER_UID'];

    const onSnapshot = ({ resolve }) => (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const data = { id: change.doc.id, ...change.doc.data() };
        const pkgs = state.hourPackages;

        if (change.type === 'added') {
          commit(SET_HOUR_PACKAGES, [ data, ...pkgs ]);
        } else if (change.type === 'removed') {
          const index = pkgs.findIndex((p) => p.id === change.doc.id);

          if (index >= 0) {
            pkgs.splice(index, 1);
            commit(SET_HOUR_PACKAGES, pkgs);
          }
        } else if (change.type === 'modified') {
          const index = pkgs.findIndex((p) => p.id === change.doc.id);

          if (index >= 0) {
            pkgs[index] = data;
            commit(SET_HOUR_PACKAGES, pkgs);
          }
        }
      });

      resolve();
    }

    commit(SET_HOUR_PACKAGES, []);

    await new Promise((resolve, reject) => {
      const query = firestoreDb.collection('hour-packages')
        .where('userId', '==', userUid)
        .orderBy('createdAt', 'asc');

      const unsubscriber = query.onSnapshot(onSnapshot({ resolve }), reject);
      commit(SET_UNSUBSCRIBERS, { type: 'hour-package', unsubscribers: [ unsubscriber ] });
    });
  },

  async fetchLoose(_, params) {
    const {
      userId,
      spaceId,
      saasId,
      limit = 5,
      offset,
    } = params;

    let query = firestoreDb
      .collection('events')
      .where('userId', '==', userId)
      .where('details.product', '==', 'loose');

    if (saasId) {
      query = query.where('saasId', '==', saasId);
    }

    if (spaceId) {
      query = query.where('spaceId', '==', spaceId);
    }

    if (offset) {
      query = query.startAfter(offset);
    }

    const events = await query
      .orderBy('start', 'desc')
      .limit(limit)
      .get()
      .then(handleSnapshot);
    const spaceIds = [ ...new Set(events.map((e) => e.spaceId)) ];

    if (spaceIds.length) {
      const spaces = await firestoreDb
        .collection('spaces')
        .where('id', 'in', spaceIds)
        .get()
        .then(handleSnapshot);

      events.forEach((e) => {
        const space = spaces.find((s) => s.id === e.spaceId);
        e.space = space;
      });
    }

    return events;
  },

  async fetchSubscription(_, query) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/products/subscription',
        {
          params: query,
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchHourPackage(_, query) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/products/hour-package',
        {
          params: query,
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchServicesDetails(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        `/products/services/details`,
        {
          params,
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchServices(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        `/products/services`,
        {
          params,
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async cancelService(_, requestId) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        `/products/services/cancel`,
        { requestId },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchProduct(_, { id, product }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        `/products`,
        {
          params: { id, product },
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async updateAutoRenewal(_, { product, productId, autoRenewal }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.put(
        '/products/auto-renewal',
        { product, productId, autoRenewal },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  unsubscribeAll({ getters, commit }, type) {
    const prevUnsubscribers = getters.GET_UNSUBSCRIBERS;
    const keys = Object.keys(prevUnsubscribers)
      .filter((key) => !type || type === key);

    keys.forEach((key) => {
      const unsubs = prevUnsubscribers[key];
      unsubs.forEach((p) => p());
      commit(SET_UNSUBSCRIBERS, { key, unsubscribers: [] });
    });
  },

  reset({ commit, dispatch }) {
    dispatch('unsubscribeAll');
    commit(RESET);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
